import React, { useState } from 'react';
import Subscribe from './Subscribe';
import Button from '@mui/material/Button';
// import { useHistory } from "react-router-dom";
import kickstarter from '../resources/images/kickstarter.png';
import '../style/Information.css';

function Information() {
    const [subscriptionPanel, setOpenSubscriptionState] = useState(false);
    // let history = useHistory();

    function navigateToKickstarter() {
        // history.push('/kickstarter');
        window.open('https://www.kickstarter.com/projects/ataraxiausa/ataraxia-bedsheets', '_blank');
    }

    function openSubscriptionPanel() {
        setOpenSubscriptionState(true);
    }

    function closeSubscriptionPanel() {
        setOpenSubscriptionState(false);
    }

    return (
        <div>
            {/* <hr /> */}
            <div className='information'>
                {/* {
                    !subscriptionPanel &&
                    <div className='information__purchase'>
                        <p>Purchase our bedsheets on</p>
                        <img
                            src={kickstarter}
                            alt=''
                            onClick={navigateToKickstarter}
                        />
                    </div>
                } */}
                {
                    !subscriptionPanel &&
                    <div className='information__purchase'>
                        <p>Get notified when available for purchase and receive $50 off</p>
                        <Button
                            className='subscription__button'
                            onClick={openSubscriptionPanel}
                        >SUBSCRIBE
                        </Button>
                    </div>
                }
                {
                    subscriptionPanel &&
                    <div className='subscription__panel'>
                        <Subscribe
                            closeSubscriptionPanel={closeSubscriptionPanel}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default Information