import React from 'react';
import ataraxiaLogo from '../resources/images/ataraxia_logo.png';
import '../style/Header.css';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { useHistory } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

function Header({ linksEnabled }) {
    let history = useHistory();

    function navigateHome() {
        history.push('/');
    }

    return (
        <div className='header'>
            <img
                src={ataraxiaLogo}
                alt=''
                onClick={navigateHome}
            />
            {
                linksEnabled &&
                <div className='header__links'>
                    {/* <p>OUR STORY</p> */}
                    {/* <p>SHOP</p>
                    <p>CONTACT</p> */}
                    <p><a href="mailto: hello@ataraxiausa.com" style={{ all: 'unset' }}><MailOutlineIcon className='header__link__icon__contact' /></a></p>
                    <HashLink className='header__link__icon__shop' smooth to={'/#shop'}>
                        <LocalMallIcon />
                    </HashLink>
                </div>
            }
        </div>
    )
}

export default Header;