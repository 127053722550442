import React from 'react'
import '../style/Values.css'
import Blogs from './Blogs'
import ValueTile from './ValueTile'

function Values() {
    return (
        <div className='values'>
            <div className='values__content'>
                <div className='values__panel'>
                    <div className='values__headline'>
                        <p>Why Ataraxia?</p>
                    </div>
                    <div className='values__text'>
                        <p>At Ataraxia, we are determined to provide
                            high-quality and luxury products.
                            We strive to make a life changing impact by
                            introducing bedsheets with colors and unique
                            patterns that are thoughtfully chosen.</p>
                    </div>
                </div>
                <div className='values__section'>
                    <ValueTile
                        title="Quality"
                        description="We manufacture premium quality products.
                        We only utilize super high quality raw material and pay attention to the details."
                    />
                    <ValueTile
                        title="Accuracy"
                        description="We use premium grade digital printing. This helps us bring thoughtfully developed colorful designs to
                        life with 100% color accuracy. Moreover, each and every product goes through a throrough
                        quality check process leaving zero room for errors."
                    />
                    <ValueTile
                        title="180 Days Warranty"
                        description="We have pledged customer satisfaction as our first value. We strongly believe in the quality of
                        our products and durability of the colorful designs. We will do everything to make things right,
                        if our bedsheets get damaged or the colors fed away within 180 days of your purchase."
                    />
                    <ValueTile
                        title="Best value for money"
                        description="We work directly with our manufacturer for making all the wonderful products. As a result,
                        we are able to make them available at a fair price that is only going to leave you spellbound."
                    />
                </div>
                <Blogs />
            </div>
        </div>
    )
}

export default Values
