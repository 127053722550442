import React from 'react';
import '../style/Title.css'
import ataraxiaLogo from '../resources/images/ataraxia_logo.png';
function Title() {

    return (
        <div className='title'>
            <img
                src={ataraxiaLogo}
                alt=''
            />
        </div>
    )
}

export default Title;