import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import {
    useSubmit,
    withFormControl} from 'react-form-component'

export const theme = {
    sizes: {
      inputHeight: 40,
      inputWidth: '100%',
      inputGutterBottom: 30,
      inputSidePaddings: 10,
      labelGutterBottom: 10,
      narrowInputWidth: 140,
      inlineLabelWidth: 130,
      borderRadius: 0,
      borderWidth: 2,
      rowGutter: 50,
      formActionsDistance: 60,
      onlyBottomBorder: false,
      largeInputExtraHeight: 10,
      prefixExtraSpacing: 20,
    },
  
    colors: {
      accent: '#1fc59c',
      inputText: '#3d4348',
      inputBorder: '#e6edf4',
      inputBg: 'white',
      inputFocusShadow: 'none',
      label: 'inherit',
      fill: '#e6edf4',
      placeholder: '#c3d4e4',
      success: '#00a651',
      error: '#e50038',
    },
  
    typography: {
      inputFontSize: 16,
      inputFontWeight: 'normal',
      labelFontSize: 14,
      labelFontWeight: 'normal',
      helpFontSize: 12,
    },
  
    breakpoints: {
      xs: 0,
      sm: '768px',
      md: '1000px',
      lg: '1400px',
    },
  
    textLabels: {
      formInvalid: 'The form is not filled correctly.',
      mandatoryField: 'This field is mandatory.',
      minChars: 'This field should have at least :length: characters.',
      passwordInvalid: 'Password should be at least 6 characters long.',
      emailInvalid: 'This is not a valid email address.',
      urlInvalid: 'This is not a valid URL.',
      phoneInvalid: 'This is not a valid phone number.',
      postCodeInvalid: 'This is not a valid postal code.',
      fieldgroupInvalid: 'Some rows are not filled correctly.',
      addMore: 'Add more',
    },
  
    toastContainerProps: {
      hideProgressBar: true,
      autoClose: 5000,
    },
  
    errorNotificationFunc: message => toast.error(message),
    customValidationFunction: (value, type) => {
        const isValidDocUniqueName = id => id
        && /^[a-zA-Z]{1,15}$/
            .test(id);
        switch (type) {
          case 'letters':
            if(isValidDocUniqueName(value)) {
              return false;
            } else {
              return 'Letters only. Length < 15 characters.';
            }
            
          default:
            return false
        }
      },
  }


const CustomRawInput = ({
    name,
    value,
    mandatory,
    setValue,
}) =>
<input
    className='rfc-input'
    value={value}
    onChange={e => setValue(name, e.target.value, mandatory, { type: 'letters' })}
/>

export const CustomInput = withFormControl(CustomRawInput);

export const SubmitButton = ({
    onClick,
    reset,
    loading,
    suppressErrorMessage,
    children,
    ...otherProps
  }) => {
    const submit = useSubmit(suppressErrorMessage)
    return (
      <Button
        className='form__buttons__button'
        onClick={e => submit && submit(e, onClick, reset)}
        {...otherProps}
      >{children}</Button>
    )
  }