import React from 'react'
import { Link } from 'react-router-dom'
import '../style/Error.css'

function Error() {

    return (
        <div>
            <div className='error__page'>
                <h1>Sorry, this page isn't available!</h1>
                <p>Click here to explore <Link className='drop__landing' to='/' >Ataraxia</Link> </p>
            </div>
            {/* <div className='error__button'>
                
            </div> */}
        </div>

    )
}

export default Error