import React, { useState } from 'react'
import '../style/ValueTile.css'

function ValueTile({ title, description }) {
    const [more, setMore] = useState(false);

    function expandDescription() {
        setMore(!more);
    }
    return (
        <div className='value__tile'>
            <h2>{title}</h2>
            <p>{more ? description : description.substr(0,130)} 
            {description.length > 140 && <a className='more__text' onClick={expandDescription}>{more ? " . . .less" : " . . .more"}</a>}</p>
        </div>
    )
}

export default ValueTile
