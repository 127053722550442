import React, { useState } from 'react'
import '../style/SelectionTile.css'
import ItemDetails from './ItemDetails';
import CancelIcon from '@mui/icons-material/Cancel';
// import BuyButton from './BuyButton';

function SelectionTile({ productTitle, imageSrcOne, imageSrcTwo, imageSrcThree, productDescription, rangeDescription, statement }) {
    const [selectedBullet, setSelectedBullet] = useState(1);
    const [openItemFlag, toggleOpenItemFlag] = useState(false);

    function selectBulletOne() {
        setSelectedBullet(1);
    }

    function selectBulletTwo() {
        setSelectedBullet(2);
    }

    function selectBulletThree() {
        setSelectedBullet(3);
    }

    function openItemDetails() {
        toggleOpenItemFlag(true);
        document.body.style.overflow = 'hidden';
    }

    function closeItemDetails() {
        toggleOpenItemFlag(false);
        document.body.style.overflow = 'unset';
    }

    return (
        <div className='selection__tile'>
            <div className='selection__tile__details'>
                <>
                    {
                        selectedBullet == 1 &&
                        <img
                            src={imageSrcTwo}
                            alt=''
                            onClick={openItemDetails}
                        />
                    }
                    {
                        selectedBullet == 2 &&
                        <img
                            src={imageSrcOne}
                            alt=''
                            onClick={openItemDetails}
                        />
                    }
                    {/* {
                        selectedBullet == 3 &&
                        <img
                            src={imageSrcThree}
                            alt=''
                            onClick={openItemDetails}
                        />
                    } */}
                </>
                <div className='selection__tile__bullets'>
                    {
                        imageSrcOne &&
                        <div className='bullet' onClick={selectBulletOne}>
                            <img
                                className={selectedBullet == 1 ? 'selected__img' : ''}
                                src={imageSrcTwo}
                                alt=''
                            />
                        </div>
                    }
                    {
                        imageSrcTwo &&
                        <div className='bullet' onClick={selectBulletTwo}>
                            <img
                                className={selectedBullet == 2 ? 'selected__img' : ''}
                                src={imageSrcOne}
                                alt=''
                            />
                        </div>
                    }
                    {/* {
                        imageSrcThree &&
                        <div className='bullet' onClick={selectBulletThree}>
                            <img
                                className={selectedBullet == 3 ? 'selected__img' : ''}
                                src={imageSrcThree}
                                alt=''
                            />
                        </div>
                    } */}
                </div>
                <h3>{productTitle}</h3>
                {/* <p>Price</p> */}
                {/* <BuyButton /> */}
            </div>
            {
                openItemFlag &&
                <ItemDetails
                    productTitle={productTitle}
                    imageSrcOne={imageSrcOne}
                    imageSrcTwo={imageSrcTwo}
                    imageSrcThree={imageSrcThree}
                    productDescription={productDescription}
                    rangeDescription={rangeDescription}
                    statement={statement}
                    closeItemDetails={closeItemDetails}
                />
            }
        </div>
    )
}

export default SelectionTile
