import React, { useState } from 'react'
import '../style/ItemDetails.css'
import CancelIcon from '@mui/icons-material/Cancel';
import Slider from "react-slick";
import SetDetails from './SetDetails';

function ItemDetails({ productTitle, imageSrcOne, imageSrcTwo, imageSrcThree, productDescription, rangeDescription, statement, closeItemDetails }) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        autoPlaySpeed: 0,
    };

    const [selectedBullet, setSelectedBullet] = useState(1);
    const [selectedTab, setSelectedTab] = useState(1);

    function selectBulletOne() {
        setSelectedBullet(1);
    }

    function selectBulletTwo() {
        setSelectedBullet(2);
    }

    function selectBulletThree() {
        setSelectedBullet(3);
    }

    function selectTabOne() {
        setSelectedTab(1);
    }

    function selectTabTwo() {
        setSelectedTab(2);
    }

    function selectTabThree() {
        setSelectedTab(3);
    }

    return (
        <div className='item'>
            <div className='item__content'>
                <div className='item__title'>
                    <CancelIcon className='cancel__icon' onClick={closeItemDetails} />
                    <h2>{productTitle}</h2>
                </div>
                <div className='item__details'>
                    <div className='item__image__window'>
                        <div className='slider_window'>
                            <Slider {...settings}>
                                <div className='slider__item__image'>
                                    <img
                                        src={imageSrcOne}
                                        alt=''
                                    />
                                </div>
                                <div className='slider__item__image'>
                                    <img
                                        src={imageSrcThree}
                                        alt=''
                                    />
                                </div>
                                <div className='slider__item__image'>
                                    <img
                                        src={imageSrcTwo}
                                        alt=''
                                    />
                                </div>
                            </Slider>
                        </div>
                        <div className='item__image__bullets'>
                            {
                                imageSrcOne &&
                                <div className='item__image' onClick={selectBulletOne}>
                                    <img
                                        className={selectedBullet == 1 ? 'selected__img' : ''}
                                        src={imageSrcOne}
                                        alt=''
                                    />
                                </div>
                            }
                            {
                                imageSrcTwo &&
                                <div className='item__image' onClick={selectBulletTwo}>
                                    <img
                                        className={selectedBullet == 2 ? 'selected__img' : ''}
                                        src={imageSrcTwo}
                                        alt=''
                                    />
                                </div>
                            }
                            {
                                imageSrcThree &&
                                <div className='item__image' onClick={selectBulletThree}>
                                    <img
                                        className={selectedBullet == 3 ? 'selected__img' : ''}
                                        src={imageSrcThree}
                                        alt=''
                                    />
                                </div>
                            }
                        </div>
                        <div className='item__images'>
                            {
                                selectedBullet == 1 &&
                                <img
                                    src={imageSrcOne}
                                    alt=''
                                />
                            }
                            {
                                selectedBullet == 2 &&
                                <img
                                    src={imageSrcTwo}
                                    alt=''
                                />
                            }
                            {
                                selectedBullet == 3 &&
                                <img
                                    src={imageSrcThree}
                                    alt=''
                                />
                            }
                        </div>
                        {/* <div className='item__image__bullets__horizontal'>
                            {
                                imageSrcOne &&
                                <div className='item__image' onClick={selectBulletOne}>
                                    <img
                                        className={selectedBullet == 1 ? 'selected__img' : ''}
                                        src={imageSrcOne}
                                        alt=''
                                    />
                                </div>
                            }
                            {
                                imageSrcTwo &&
                                <div className='item__image' onClick={selectBulletTwo}>
                                    <img
                                        className={selectedBullet == 2 ? 'selected__img' : ''}
                                        src={imageSrcTwo}
                                        alt=''
                                    />
                                </div>
                            }
                            {
                                imageSrcThree &&
                                <div className='item__image' onClick={selectBulletThree}>
                                    <img
                                        className={selectedBullet == 3 ? 'selected__img' : ''}
                                        src={imageSrcThree}
                                        alt=''
                                    />
                                </div>
                            }
                        </div> */}
                    </div>
                    <div className='item__description'>
                        <h2>{productDescription}</h2>
                        {/* <h4>{rangeDescription}</h4>
                            <p>{statement}</p> */}
                        {/* <h4>$100&nbsp;&nbsp;<strike>$150</strike>&nbsp;&nbsp;<span className='highlight'>Save 33%</span></h4> */}
                        <p>Bundle includes: 1 Fitted Sheet, 1 Flat Sheet, 2 Pillow Cases</p>
                        <div className='item__tabs'>
                            <div className='item__tab__headline'>
                                <div className={selectedTab == 1 ? 'selected__tab' : 'tab'} onClick={selectTabOne}>
                                    <p>Details</p>
                                </div>
                                <div className={selectedTab == 2 ? 'selected__tab' : 'tab'} onClick={selectTabTwo}>
                                    <p>Care</p>
                                </div>
                                <div className={selectedTab == 3 ? 'selected__tab' : 'tab'} onClick={selectTabThree}>
                                    <p>Info</p>
                                </div>
                            </div>
                            <div className='item__tab__data'>
                                {
                                    selectedTab == 1 &&
                                    <>
                                        <p>{rangeDescription}</p>
                                        <p>Made In India.</p>
                                        <li>100% Cotton</li>
                                        <li>Ultra Soft</li>
                                        <li>Sateen Weave</li>
                                        <li>330 TC</li>
                                        <li>Machine Washable</li>
                                        <li>Long Lasting Prints</li>
                                    </>
                                }
                                {
                                    selectedTab == 2 &&
                                    <>
                                        <p>{statement}</p>
                                        <li>Machine Wash</li>
                                        <li>No Bleach</li>
                                        <li>Tumble dry low</li>
                                        <li>Iron low heat if needed</li>
                                    </>
                                }
                                {
                                    selectedTab == 3 &&
                                    <div className='specsgrid-details'>
                                        <div className="size1"><h3>Twin</h3></div>
                                        <div className="size2"><h3>Queen</h3></div>
                                        <div className="size3"><h3>King</h3></div>
                                        <div className="type1"><p>Flat Sheet</p></div>
                                        <div className="type2"><p>Fitted Sheet</p></div>
                                        <div className="type3"><p>Pillow Covers x 2</p></div>
                                        <div className="type4"><p>Prices</p></div>
                                        <div className="size1flat"><p>100" x 74"</p></div>
                                        <div className="size1fitted"><p>38" x 75" x 18"</p></div>
                                        <div className="size1pillows"><p>20' x 26"</p></div>
                                        <div className="size2flat"><p>106" x 96"</p></div>
                                        <div className="size2fitted"><p>60" x 80" x 18"</p></div>
                                        <div className="size2pillows"><p>20" x 30"</p></div>
                                        <div className="size3flat"><p>112" x 106"</p></div>
                                        <div className="size3fitted"><p>76" x 80" x 18"</p></div>
                                        <div className="size3pillows"><p>20' x 36"</p></div>
                                        <div className='price1'><p>$130</p></div>
                                        <div className='price2'><p>$140</p></div>
                                        <div className='price3'><p>$150</p></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div className='item__images'>
                            <img
                                src={imageSrcOne}
                                alt=''
                            />
                            <img
                                src={imageSrcThree}
                                alt=''
                            />
                            <img
                                src={imageSrcTwo}
                                alt=''
                            />
                        </div> */}
                </div>
            </div>
        </div >

    )
}

export default ItemDetails
