import React, { useState } from 'react';
import { theme, CustomInput, SubmitButton } from './FormTheme'
import { toast } from 'react-toastify';
import { ERROR_MESSAGE } from './Constants';
import { fire, appCheck } from './Fire';
import { Button, CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useGoogleReCaptcha, } from 'react-google-recaptcha-v3';
import Form, {
    FormThemeProvider,
    Input
} from 'react-form-component';
import '../style/Subscribe.css';
import { getToken } from 'firebase/app-check'
import { doc, setDoc, getDoc, getFirestore, addDoc, collection } from 'firebase/firestore';
import axios from 'axios';

const db = getFirestore(fire);

function Subscribe({ closeSubscriptionPanel }) {

    const { executeRecaptcha } = useGoogleReCaptcha();
    const recaptchaVerifyFunctionURL = process.env.REACT_APP_FIREBASE_FUNCTION_RECAPTCHA_VERIFY_URL;
    const subscription = { READY: "READY", PENDING: "PENDING", SUCCESS: "SUCCESS", BOTACTIVITY: "BOTACTIVITY" };
    const [subscriptionState, setSubscriptionState] = useState(subscription.READY);
    // const [firstname, setFirstname] = useState(null);
    // const [lastname, setLastname] = useState(null);
    const [email, setEmail] = useState(null);

    function lockActions() {
        setSubscriptionState(subscription.BOTACTIVITY);
    }

    function indicateExecuting(e) {
        setSubscriptionState(subscription.PENDING);
        // setFirstname(e.firstname);
        // setLastname(e.lastname);
        setEmail(e.email);
    }

    function stopExecuting() {
        setSubscriptionState(subscription.READY);
    }

    function markSubscriptionExecuted() {
        setSubscriptionState(subscription.SUCCESS);
    }

    async function getRecaptchaScore(recaptchaToken, apiToken) {
        var remoteip = '0.0.0.0';
        remoteip = await axios.get('https://geolocation-db.com/json/');
        const response = await axios.post(
            recaptchaVerifyFunctionURL,
            {
                recaptchaToken,
                remoteip,
            },
            {
                headers: {
                    'X-Firebase-AppCheck': apiToken,
                },
            },
        );

        return response.data.score;
    }

    async function isThisABot(apiToken) {
        if (!executeRecaptcha) {
            stopExecuting();
            toast.error(ERROR_MESSAGE.TECHNICAL_ISSUE);
            return;
        }

        const recaptchaToken = await executeRecaptcha('subscription');
        const response = await getRecaptchaScore(recaptchaToken, apiToken);
        return (response <= 0.5);
    }

    async function isRecordPresent(email) {
        try {
            const docRef = doc(db, "subscriptions", email);
            const docSnap = await getDoc(docRef);
            return (docSnap.exists());
        } catch (error) {
            return false;
        }
    }

    async function createUnsubscribeKey(email) {
        try {
            const docRef = await addDoc(collection(db, "unsubscribe"), {
                email: email
            });
            return docRef.id;
        } catch (error) {
            return null;
        }

    }

    async function executeSubscription(e) {
        indicateExecuting(e);

        const email = e.email.toLowerCase().trim();
        const isPresent = await isRecordPresent(email);
        const unsubscribeDocRef = await createUnsubscribeKey(email);
        try {
            if (!isPresent && unsubscribeDocRef) {
                await setDoc(doc(db, "subscriptions", email), {
                    // firstname: e.firstname.trim(),
                    // lastname: e.lastname.trim(),
                    email: email
                });
                markSubscriptionExecuted();
            } else {
                stopExecuting();
                const errorMessage = (isPresent) ? ERROR_MESSAGE.EMAIL_ALREADY_EXISTS : ERROR_MESSAGE.TECHNICAL_ISSUE;
                toast.error(errorMessage);
            }
        } catch (error) {
            stopExecuting();
            toast.error(ERROR_MESSAGE.TECHNICAL_ISSUE);
        }
    }

    async function createSubscriptionRecord(e) {
        const tokenResponse = await getToken(appCheck, false);
        const isBot = await isThisABot(tokenResponse.token);
        if (isBot) {
            // No actions will be performed due to a suspicious activity
            lockActions();
        } else {
            // Fetches the current App Check token and attaches to the most recent in-flight request. 
            await getToken(appCheck, false);
            
            // Executes the subscription workflow
            await executeSubscription(e);
        }
    }

    function existSubscriptionFlow() {
        closeSubscriptionPanel();
        setSubscriptionState(subscription.READY);
    }

    return (
        <div className='subscribe'>
            {
                subscriptionState === subscription.READY &&
                <>
                    {/* <p>Subscribe to receive email updates and newsletters!</p> */}
                    <FormThemeProvider theme={theme}>
                        <Form fields={['email']} allMandatory='true'>
                            {/* <CustomInput
                                name='firstname'
                                type='letters'
                                label='First Name'
                                initialValue={firstname}
                            />
                            <CustomInput
                                name='lastname'
                                type='letters'
                                label='Last Name'
                                initialValue={lastname}
                            /> */}
                            <Input
                                name='email'
                                type='email'
                                label='Email'
                                initialValue={email}
                            />
                            <div className='form__buttons'>
                                <SubmitButton
                                    onClick={createSubscriptionRecord}
                                >SUBSCRIBE</SubmitButton>
                                <Button
                                    className='form__buttons__button'
                                    onClick={existSubscriptionFlow}
                                >Cancel</Button>
                            </div>
                        </Form>
                    </FormThemeProvider>
                </>
            }
            {
                (subscriptionState === subscription.PENDING || subscriptionState === subscription.SUCCESS) &&
                <>
                    {(subscriptionState === subscription.PENDING) && <CircularProgress color="info" />}
                    {
                        (subscriptionState === subscription.SUCCESS) &&
                        <CancelIcon
                            className='cancel__icon'
                            onClick={closeSubscriptionPanel}
                        />
                    }
                    {(subscriptionState === subscription.SUCCESS) && <><h2>Welcome onboard!</h2><p>Thank you for your interest.</p></>}
                    <div className='subscription__data'>
                        {/* <p>{firstname}</p>
                        <p>{lastname}</p> */}
                        <p>{email}</p>
                    </div>
                </>
            }
            {
                (subscriptionState === subscription.BOTACTIVITY) &&
                <>
                    <h2 style={{ color: 'red' }}>BOT ACTIVITY DETECTED!</h2>
                    <SmartToyIcon
                        className='bot__icon'
                    />
                </>

            }
        </div>
    )
}

export default Subscribe;