import React from 'react';
import Exurbia from './Exurbia';
import Poche from './Poche';
import '../style/Patterns.css';
import Specifications from './Specifications';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import rose from '../resources/images/rose.jpg';
import rosestack from '../resources/images/rose-stack.jpeg';
import rosewave from '../resources/images/rose-wave.jpeg';
import blue from '../resources/images/blue.jpg';
import purple from '../resources/images/purple.jpg';
import green from '../resources/images/green.jpg';
import bluestack from '../resources/images/blue-stack.jpeg';
import purplestack from '../resources/images/purple-stack.jpeg';
import greenstack from '../resources/images/green-stack.jpeg';
import bluewave from '../resources/images/blue-wave.jpeg';
import purplewave from '../resources/images/purple-wave.jpeg';
import greenwave from '../resources/images/green-wave.jpeg';
import SelectionTile from './SelectionTile';



function Patterns() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className='patterns'>
            <div className='patterns__headline'>
                <p>Our Bedsheets</p>
            </div>
            {/* <hr style={{width:'20%'}}/> */}
            <Exurbia />
            <hr className='hr__line'/>
            <Poche />
            {/* <div className='patterns__carousel'>
                <Carousel
                    // swipeable={false}
                    // draggable={false}
                    // showDots={true}
                    responsive={responsive}
                // ssr={true} // means to render carousel on server-side.
                // infinite={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                // autoPlaySpeed={1000}
                // keyBoardControl={true}
                // customTransition="all .5"
                // transitionDuration={500}
                // containerClass="carousel-container"
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                // dotListClass="custom-dot-list-style"
                // itemClass="carousel-item-padding-40-px"
                >
                    <SelectionTile
                        productTitle='Exurbia Rose Garden'
                        imageSrcOne={rosestack}
                        imageSrcTwo={rose}
                        imageSrcThree={rosewave}
                        productDescription='This bed sheet belongs to our Exurbia range. It has a lifelike rose tree design.'
                        rangeDescription='The Exurbia range designs synonymous with spring, representing renewal, beauty, and warmth. These designs invoke amicable feelings of happiness, harmony, and balance.'
                        statement='As we only use cotton as a raw material, these bed sheets are extremely soft. The print is long lasting and the colors will not fade away. It can easily be washed in a washing machine. This product was prepared under detailed observation and extreme care to meet our high production standards. As a result, there is very little to no margin for any errors.'
                    />
                    <SelectionTile
                        productTitle='Poché Blue Rise'
                        imageSrcOne={bluestack}
                        imageSrcTwo={blue}
                        imageSrcThree={bluewave}
                        productDescription='This bed sheet belongs to our Poché range. It has a blue design with arrows specifically placed to form a beautiful silhouette.'
                        rangeDescription='The Poché range uses geometric patterns and symbols that have long been associated with varied beliefs and principles. This intricate range represents open-mindedness, discipline, unity, and efficiency.has a blue design that was thoughtfully chosen.'
                        statement='As we only use cotton as a raw material, these bed sheets are extremely soft. The print is long lasting and the colors will not fade away. It can easily be washed in a washing machine. This product was prepared under detailed observation and extreme care to meet our high production standards. As a result, there is very little to no margin for any errors.'
                    />
                    <SelectionTile
                        productTitle='Poché Purple Plaid'
                        imageSrcOne={purplestack}
                        imageSrcTwo={purple}
                        imageSrcThree={purplewave}
                        productDescription='This bed sheet belongs to our Poché range. It has a purple design with stripes specifically placed to form a beautiful silhouette.'
                        rangeDescription='The Poché range uses geometric patterns and symbols that have long been associated with varied beliefs and principles. This intricate range represents open-mindedness, discipline, unity, and efficiency.has a blue design that was thoughtfully chosen.'
                        statement='As we only use cotton as a raw material, these bed sheets are extremely soft. The print is long lasting and the colors will not fade away. It can easily be washed in a washing machine. This product was prepared under detailed observation and extreme care to meet our high production standards. As a result, there is very little to no margin for any errors.'
                    />
                    <SelectionTile
                        productTitle='Poché Green Summer'
                        imageSrcOne={greenstack}
                        imageSrcTwo={green}
                        imageSrcThree={greenwave}
                        productDescription='This bed sheet belongs to our Poché range. It has a green-gray design with squares and lines specifically placed to form a beautiful silhouette.'
                        rangeDescription='The Poché range uses geometric patterns and symbols that have long been associated with varied beliefs and principles. This intricate range represents open-mindedness, discipline, unity, and efficiency.has a blue design that was thoughtfully chosen.'
                        statement='As we only use cotton as a raw material, these bed sheets are extremely soft. The print is long lasting and the colors will not fade away. It can easily be washed in a washing machine. This product was prepared under detailed observation and extreme care to meet our high production standards. As a result, there is very little to no margin for any errors.'
                    />
                </Carousel>
            </div> */}
            <hr className='hr__line' />
            <Specifications />
            <div className='shop__window' id='shop' />
            <hr className='hr__line' />
        </div>
    )
}

export default Patterns