import React, { useState } from 'react'
import '../style/Poche.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import blue from '../resources/images/blue.jpg';
import purple from '../resources/images/purple.jpg';
import green from '../resources/images/green.jpg';
import bluestack from '../resources/images/blue-stack.jpeg';
import purplestack from '../resources/images/purple-stack.jpeg';
import greenstack from '../resources/images/green-stack.jpeg';
import bluewave from '../resources/images/blue-wave.jpeg';
import purplewave from '../resources/images/purple-wave.jpeg';
import greenwave from '../resources/images/green-wave.jpeg';
import SelectionTile from './SelectionTile';
import poche from '../resources/images/poche.jpg';

function Poche() {

    const [expandText, setExpandText] = useState(false);

    function toggleExpandText() {
        setExpandText(!expandText);
    }

    return (
        <div className='poche'>
            <p>POCHÉ</p>
            {/* <div className='poche__background'>
                <img
                    src={poche}
                    alt=''
                />
            </div> */}
            {/* <div className='poche__text'
                onClick={toggleExpandText}
            >
                {
                    !expandText &&
                    <AddCircleOutlineIcon className='add__icon' />
                }
                {
                    expandText &&
                    <p>As bold as the designs it flaunts, the Poché range uses geometric patterns and symbols
                        that have long been associated with varied beliefs and principles.
                        This intricate range represents open-mindedness, discipline, unity, and efficiency.</p>
                }
            </div> */}
            <div className='choices__section'>
                <SelectionTile
                    productTitle='Poché Blue Rise'
                    imageSrcOne={bluestack}
                    imageSrcTwo={blue}
                    imageSrcThree={bluewave}
                    productDescription='This bed sheet belongs to our Poché range. It has a blue design with arrows specifically placed to form a beautiful silhouette.'
                    rangeDescription='The Poché range uses geometric patterns and symbols that have long been associated with varied beliefs and principles. This intricate range represents open-mindedness, discipline, unity, and efficiency.has a blue design that was thoughtfully chosen.'
                    statement='As we only use cotton as a raw material, these bed sheets are extremely soft. The print is long lasting and the colors will not fade away. It can easily be washed in a washing machine. This product was prepared under detailed observation and extreme care to meet our high production standards. As a result, there is very little to no margin for any errors.'
                />
                <SelectionTile
                    productTitle='Poché Purple Plaid'
                    imageSrcOne={purplestack}
                    imageSrcTwo={purple}
                    imageSrcThree={purplewave}
                    productDescription='This bed sheet belongs to our Poché range. It has a purple design with stripes specifically placed to form a beautiful silhouette.'
                    rangeDescription='The Poché range uses geometric patterns and symbols that have long been associated with varied beliefs and principles. This intricate range represents open-mindedness, discipline, unity, and efficiency.has a blue design that was thoughtfully chosen.'
                    statement='As we only use cotton as a raw material, these bed sheets are extremely soft. The print is long lasting and the colors will not fade away. It can easily be washed in a washing machine. This product was prepared under detailed observation and extreme care to meet our high production standards. As a result, there is very little to no margin for any errors.'
                />
                <SelectionTile
                    productTitle='Poché Green Summer'
                    imageSrcOne={greenstack}
                    imageSrcTwo={green}
                    imageSrcThree={greenwave}
                    productDescription='This bed sheet belongs to our Poché range. It has a green-gray design with squares and lines specifically placed to form a beautiful silhouette.'
                    rangeDescription='The Poché range uses geometric patterns and symbols that have long been associated with varied beliefs and principles. This intricate range represents open-mindedness, discipline, unity, and efficiency.has a blue design that was thoughtfully chosen.'
                    statement='As we only use cotton as a raw material, these bed sheets are extremely soft. The print is long lasting and the colors will not fade away. It can easily be washed in a washing machine. This product was prepared under detailed observation and extreme care to meet our high production standards. As a result, there is very little to no margin for any errors.'
                />
            </div>
        </div>
    )
}

export default Poche