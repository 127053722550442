import React, { useState } from 'react'
import '../style/Exurbia.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import rose from '../resources/images/rose.jpg';
import rosestack from '../resources/images/rose-stack.jpeg';
import rosewave from '../resources/images/rose-wave.jpeg';
import SelectionTile from './SelectionTile';
import exurbia from '../resources/images/exurbia.jpg';


function Exurbia() {
    const [expandText, setExpandText] = useState(false);

    function toggleExpandText() {
        setExpandText(!expandText);
    }

    return (
        <div className='exurbia'>
            <p>EXURBIA</p>
            {/* <div className='exurbia__background'>
                <img
                    src={exurbia}
                    alt=''
                />
            </div> */}
            {/* <div className='exurbia__text'
                onClick={toggleExpandText}
            >
                {
                    !expandText &&
                    <AddCircleOutlineIcon className='add__icon' />
                }
                {
                    expandText &&
                    <p>Centered around floral patterns, the designs in this range
                        are synonymous with spring, representing renewal, beauty, and warmth.
                        The Exurbia range invokes amicable feelings of happiness, harmony, and balance.</p>
                }
            </div> */}
            <div className='choices__section'>
                <SelectionTile
                    productTitle='Exurbia Rose Garden'
                    imageSrcOne={rosestack}
                    imageSrcTwo={rose}
                    imageSrcThree={rosewave}
                    productDescription='This bed sheet belongs to our Exurbia range. It has a lifelike rose tree design.'
                    rangeDescription='The Exurbia range designs synonymous with spring, representing renewal, beauty, and warmth. 
                    These designs invoke amicable feelings of happiness, harmony, and balance.'
                    statement='As we only use cotton as a raw material, these bed sheets are extremely soft. 
                    The print is long lasting and the colors will not fade away. It can easily be washed in a washing machine. This product was prepared under detailed observation and extreme care to meet our high production standards. As a result, there is very little to no margin for any errors.'
                />
                {/* <SelectionTile
                    imageSrcOne={do_not_use}
                /> */}
            </div>
        </div>
    )
}

export default Exurbia