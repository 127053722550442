import React from 'react';
import '../style/RecaptchaContainer.css'
import '../style/Banner.css';

function RecaptchaContainer() {

    return (
        <div 
            className='recaptcha__container'
            id='recaptcha__container'
        >
            
        </div>
    )
}

export default RecaptchaContainer;