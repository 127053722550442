import React, { useState } from 'react';
import '../style/Blogs.css';
import { useHistory } from "react-router-dom";

function Blogs() {

    let history = useHistory();

    function navigateBlog1() {
        history.push('/blogs/the-psychology-of-colors');
    }

    return (
        <div className='blogs'>
            {/* <div className='blog__tile'> */}
            <h2>Our thoughts</h2>
            <div
                className='blog'
                onClick={navigateBlog1}
            >
                <h3>The Psychology of Colors</h3>
            </div>
            {/* </div> */}
        </div>
    )
}

export default Blogs