import React, { useState } from 'react';
import Subscribe from './Subscribe';
import Button from '@mui/material/Button';
import Footer from './Footer';
import Header from './Header';
// import { useHistory } from "react-router-dom";
import kickstarter from '../resources/images/kickstarter.png';
import '../style/Notice.css';
import SetDetails from './SetDetails';

function Notice() {
    const [subscriptionPanel, setOpenSubscriptionState] = useState(false);
    // let history = useHistory();

    function navigateToKickstarter() {
        // history.push('/kickstarter');
        window.open('https://www.kickstarter.com/projects/ataraxiausa/ataraxia-bedsheets', '_blank');
    }

    function openSubscriptionPanel() {
        setOpenSubscriptionState(true);
    }

    function closeSubscriptionPanel() {
        setOpenSubscriptionState(false);
    }

    return (
        <div>
            <Header />
            <SetDetails />
            <div className='notice'>
                {
                    !subscriptionPanel &&
                    <div className='notice__tile'>
                        <p>Preorder our bedsheets and support us on</p>
                        {/* <img
                            src={kickstarter}
                            alt=''
                        /> */}
                        <Button
                            className='notice__subscribe'
                            onClick={navigateToKickstarter}
                        >KICKSTARTER
                        </Button>
                    </div>
                }
                {
                    !subscriptionPanel &&
                    <div className='notice__tile' >
                        <p>Get notified when available for purchase and receive $50 off</p>
                        <Button
                            className='notice__subscribe'
                            onClick={openSubscriptionPanel}
                        >SUBSCRIBE
                        </Button>
                    </div>
                }
                {
                    subscriptionPanel &&
                    <div className='notice__subscription__panel'>
                        <Subscribe
                            closeSubscriptionPanel={closeSubscriptionPanel}
                        />
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

export default Notice