import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cotton from '../resources/images/cotton.png';
import soft from '../resources/images/soft.png';
import sateen from '../resources/images/sateen.png';
import thread from '../resources/images/thread.png';
import machine from '../resources/images/machine.png';
import '../style/Features.css';

function Features() {
    const [numberOfSlides, setNumberOfSlides] = useState(5);

    function changeNumberOfSlides() {
        if (window.innerWidth < 300) {
            setNumberOfSlides(1);
        } else if (window.innerWidth < 1000) {
            setNumberOfSlides(3);
        } else {
            setNumberOfSlides(5);
        }
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: numberOfSlides,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoPlaySpeed: 10000,
    };

    useEffect(() => {
        changeNumberOfSlides();
        window.addEventListener('resize', changeNumberOfSlides);
        return () => {
            window.removeEventListener('resize', changeNumberOfSlides)
        }
    }, [numberOfSlides])

    return (
        <div className='features'>
            <p>BEDSHEET FEATURES</p>
            <div className='features__section'>
                <Slider {...settings}>
                    <div className='features__tile'>
                        <p>100% Cotton</p>
                        <img
                            src={cotton}
                            alt=''
                        />
                    </div>
                    <div className='features__tile'>
                        <p>Ultra Soft</p>
                        <img
                            src={soft}
                            alt=''
                        />
                    </div>
                    <div className='features__tile'>
                        <p>Sateen Weave</p>
                        <img
                            src={sateen}
                            alt=''
                        />
                    </div>
                    <div className='features__tile'>
                        <p>330 TC</p>
                        <img
                            src={thread}
                            alt=''
                        />
                    </div>
                    <div className='features__tile'>
                        <p>Machine Wash</p>
                        <img
                            src={machine}
                            alt=''
                        />
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default Features
