import React from 'react'
import '../style/SetDetails.css'

function SetDetails() {
    return (
        <div className='setdetails'>
            {/* <div className='setdetails__content'>
                <p>Flat Sheet</p>
                <h4>·</h4>
                <p>Fitted Sheet</p>
                <h4>·</h4>
                <p>Pillow Covers x 2</p>
            </div>
            <div className='setdetails__block'>
                <h3>TWIN</h3>
                <div className='setdetails__sizes'>
                    <div className='setdetails__sizes__size'>
                        <p>100" x 74"</p>
                    </div>
                    <h4>|</h4>
                    <div className='setdetails__sizes__size'>
                        <p>38" x 75" x 18"</p>
                    </div>
                    <h4>|</h4>
                    <div className='setdetails__sizes__size'>
                        <p>20' x 26"</p>
                    </div>
                </div>
            </div>
            <div className='setdetails__block'>
                <h3>QUEEN</h3>
                <div className='setdetails__sizes'>
                    <div className='setdetails__sizes__size'>
                        <p>106" x 96"</p>
                    </div>
                    <h4>|</h4>
                    <div className='setdetails__sizes__size'>
                        <p>60" x 80" x 18"</p>
                    </div>
                    <h4>|</h4>
                    <div className='setdetails__sizes__size'>
                        <p>20" x 30"</p>
                    </div>
                </div>
            </div>
            <div className='setdetails__block'>
                <h3>KING</h3>
                <div className='setdetails__sizes'>
                    <div className='setdetails__sizes__size'>
                        <p>112" x 106"</p>
                    </div>
                    <h4>|</h4>
                    <div className='setdetails__sizes__size'>
                        <p>76" x 80" x 18"</p>
                    </div>
                    <h4>|</h4>
                    <div className='setdetails__sizes__size'>
                        <p>20' x 36"</p>
                    </div>
                </div>
            </div> */}

            <div className='specsgrid'>
            <div className="size1"><h3>Twin</h3></div>
                                        <div className="size2"><h3>Queen</h3></div>
                                        <div className="size3"><h3>King</h3></div>
                                        <div className="type1"><p>Flat Sheet</p></div>
                                        <div className="type2"><p>Fitted Sheet</p></div>
                                        <div className="type3"><p>Pillow Covers x 2</p></div>
                                        <div className="type4"><p>Prices</p></div>
                                        <div className="size1flat"><p>100" x 74"</p></div>
                                        <div className="size1fitted"><p>38" x 75" x 18"</p></div>
                                        <div className="size1pillows"><p>20' x 26"</p></div>
                                        <div className="size2flat"><p>106" x 96"</p></div>
                                        <div className="size2fitted"><p>60" x 80" x 18"</p></div>
                                        <div className="size2pillows"><p>20" x 30"</p></div>
                                        <div className="size3flat"><p>112" x 106"</p></div>
                                        <div className="size3fitted"><p>76" x 80" x 18"</p></div>
                                        <div className="size3pillows"><p>20' x 36"</p></div>
                                        <div className='price1'><p>$130</p></div>
                                        <div className='price2'><p>$140</p></div>
                                        <div className='price3'><p>$150</p></div> 
            </div>
        </div>
    )
}

export default SetDetails