import React from 'react'
import '../style/Design.css'
import exurbia from '../resources/images/exurbia.jpg';
import poche from '../resources/images/poche.jpg';
import bedsheetVideoHorizontal from "../resources/videos/bedsheets-shot-horizontal.mp4"

function Design() {
    return (
        <div className='design'>
            <div className='design__content'>
                <p>Designs</p>
                <div className='design__block__exurbia'>
                    <div className='design__block__exurbia__text'>
                        <h1>EXURBIA</h1>
                        <p>Centered around floral patterns, the designs in this range are synonymous with spring,
                            representing renewal, beauty, and warmth. The Exurbia range invokes amicable feelings of
                            happiness, harmony, and balance.</p>
                    </div>
                    <img
                        src={exurbia}
                        alt=''
                    />
                </div>
                <hr className='hr__line' />
                <div className='design__block__poche'>
                    <img
                        src={poche}
                        alt=''
                    />
                    <div className='design__block__poche__text'>
                        <h1>POCHÉ</h1>
                        <p>As bold as the designs it flaunts, the Poché range uses geometric patterns and symbols
                            that have long been associated with varied beliefs and principles.
                            This intricate range represents open-mindedness, discipline, unity, and efficiency.</p>
                    </div>
                </div>
            </div>
            {/* <video
                className='video-horizontal'
                autoPlay
                loop
                muted
            >
                <source
                    src={bedsheetVideoHorizontal}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video> */}
        </div>
    )
}

export default Design
