import React from 'react';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import '../style/Colors.css';
import PhotoSlider from './PhotoSlider';
import colorSplash from '../resources/images/paintbrush.jpg';

function Colors({ linkEnabled }) {
    let history = useHistory();

    function navigateToStory() {
        history.push('/ourstory')
    }

    return (
        <div className='colors'>
            {/* <div className='colors__background'>
                <img
                    // data-aos='fade-in'
                    src={colorSplash}
                    alt=''
                />
            </div> */}

            <div className='colors__panel'>
                <div className='story__headline'>
                    <p>OUR STORY</p>
                </div>
                <div className='story__text'>
                    <p>Ataraxia was born out of the realization that just by changing the bedsheets in our rooms,
                        we can change the world around us. Ataraxia is our collective work stemmed from our personal
                        experience of working-from-home during the COVID-19 pandemic.
                    </p>
                    <p>
                        Much like everyone else, we were
                        spending hours on end in our bedrooms—working, eating, sleeping, all at one place.
                        The confines of 4 walls became quite a lot, sinking our mood and spirits. Colors were the remedy
                        to all our issues. You dont need to remodel your bedroom to make it more bright and lively simple
                        change of sheets can get you the same effect.
                    </p>
                    <p>
                        Ataraxia is dedicated to creating premium bedsheets that combine bold and floral patterns with soothing
                        colors, creating a balance of alertness and calm. Ataraxia bedsheets, with a burst of colors,
                        can change the atmosphere of your bedroom into one that brings you joy, uplift your life force,
                        and make you feel refreshed.
                    </p>
                </div>
                <div className='story__headline'>
                    <p>Colors are the real influencers</p>
                </div>
                <div data-aos='fade-in' className='story__text'>
                    <p>Ever wondered how can you make a space pleasant, welcoming, relaxing and inspiring at the same time?
                        Colors can influence mood, spirits, temperament as well as physiological reactions.
                        Artists and interior designers have long believed that colors can significantly affect the way we
                        feel and behave.</p>
                    {/* {
                        linkEnabled &&
                        <Button
                            className='colored__link__button'
                            onClick={navigateToStory}
                        >Our Story
                        </Button>
                    } */}
                </div>
            </div>
            {/* <PhotoSlider /> */}
        </div>
    )
}

export default Colors