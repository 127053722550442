import React from 'react';
import '../style/Landing.css'
import '../style/common.css'
import Banner from './Banner';
import Colors from './Colors';
import Title from './Title';
import Footer from './Footer';
import Patterns from './Patterns';
import Information from './Information';
import PhotoSlider from './PhotoSlider';
import Features from './Features';
import Values from './Values';
import Header from './Header';
import Selection from './Selection';
import Shop from './Shop';
import Specifications from './Specifications';
import Design from './Design';
import Visual from './Visual';

function Landing() {

    return (
        <div className='landing'>
            <div className='landing__section'>
                <Banner />
                <Features />
                <Design />
                <Patterns />
                <Shop />
                <Colors
                    linkEnabled={true}
                />
                <Values />
                <Information />
            </div>
        </div>
    )
}

export default Landing;