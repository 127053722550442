import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import React from 'react';
import { useHistory } from "react-router-dom";
import '../style/Footer.css';

function Footer() {
    let history = useHistory();
    
    return (
        <div className='footer'>
            <div className='footer__content'>
                <div className='footer__links'>
                    <p><a href="mailto: hello@ataraxiausa.com" style={{ all: 'unset' }}>Contact Us</a></p>
                </div>
                <div className='footer__social'>
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=100086762401779">
                        <FacebookIcon
                            style={{
                                color: getComputedStyle(document.documentElement).getPropertyValue('--theme-color-dark')
                            }}
                        />
                    </a>
                    {/* <TwitterIcon
                        style={{
                            color: getComputedStyle(document.documentElement).getPropertyValue('--theme-color-light')
                        }}
                    /> */}
                    <a target="_blank" href="https://www.instagram.com/ataraxiacollection">
                        <InstagramIcon
                            style={{
                                color: getComputedStyle(document.documentElement).getPropertyValue('--theme-color-dark'),
                                cursor: 'pointer'
                            }}
                        />
                    </a>
                </div>
            </div>
            <div className='company__print'>
                <p>© 2022, Vivara LLC</p>
            </div>
        </div>
    )
}

export default Footer