import React from 'react'
import '../style/Shop.css'
import amazon from '../resources/images/amazon_logo.png'

function Shop() {
    return (
        <div className='shop'>
            <div className='shop__panel'>
                <div className='shop__content'>
                    <div className='shop__headline'>
                        <p>Shop</p>
                    </div>
                    <div className='shop__text'>
                        <h3>Purchase our bedsheets on Amazon.</h3>
                        {/* <p>Purchase our bedsheets on Amazon.</p> */}
                    </div>
                </div>
                <div className='shop_image'>
                    <a href="https://www.amazon.com/dp/B0C87884PS" target="_blank">
                        <img
                            src={amazon}
                            alt=''
                        />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Shop
