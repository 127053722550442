// import { Button } from '@material-ui/core';
import Button from '@mui/material/Button';
import React from 'react';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import bannerImage from '../resources/images/bed-one.jpg';
import colorSplash from '../resources/images/color-splash-calm.png';
import bedsheetVideoHorizontal from "../resources/videos/bedsheets-shot-horizontal.mp4"
import bedsheetVideoVertical from "../resources/videos/bedsheets-shot-vertical.mp4"
import '../style/Banner.css';
import '../style/KickLink.css';

function Banner() {
    return (
        <div className='banner'>
            <video
                className='video-horizontal'
                autoPlay
                loop
                muted
                playsInline
            >
                <source
                    src={bedsheetVideoHorizontal}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
            {/* <video
                className='video-vertical'
                autoPlay
                loop
                muted
            >
                <source
                    src={bedsheetVideoVertical}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video> */}
            {/* <img
                src={bannerImage}
                alt=''
            /> */}
            <div className='banner__title'>
                <h1>Experience the new era of bedsheets</h1>
                <p>Revive your bedroom and your spirits with our premium bedsheets in vibrant colors and phenomenal designs.</p>
                <div className='banner__link'>
                    <HashLink smooth to={'/#shop'}>
                        <Button
                            className='link__button'
                        >Shop Bedsheets
                        </Button>
                    </HashLink>
                </div>
            </div>

            {/* <div className='colors__background'>
                <img
                    src={colorSplash}
                    alt=''
                />
            </div> */}
            {/* <div
                    className="content"
                    onClick={ navigateToKickstarter }
                >
                    <h2>Kickstarter</h2>
                    <h2>Kickstarter</h2>
                </div> */}

        </div >
    )
}

export default Banner;