import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import Landing from "./components/Landing";
import Story from "./components/Story";
import Notice from "./components/Notice";
import RecaptchaContainer from './components/RecaptchaContainer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Blogs from './components/Blogs';
import BlogsColorPsychology from './components/BlogsColorPsychology';
import Footer from './components/Footer';
import Header from './components/Header';
import Error from './components/Error';
// import Aos from 'aos';
// import 'aos/dist/aos.css';


function App() {
  const recaptchaPublicKey = process.env.REACT_APP_RECAPTCHA_KEY;

  // useEffect(() => {
  //   Aos.init({ duration: 3000 });
  // }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={recaptchaPublicKey}
      language="English"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
      container={{ // optional to render inside custom element
        element: "recaptcha__container",
        parameters: {
          badge: 'inline', // optional, default undefined
          theme: 'dark', // optional, default undefined
          // size: 'normal',
        }
      }}
    >
      <Router>
        <div className="App">
          <Header
            linksEnabled={true}
          />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/ourstory" component={Story} />
            <Route exact path="/notice" component={Notice} />
            <Route exact path="/blogs/the-psychology-of-colors" component={BlogsColorPsychology} />
            <Route component={Error} />
          </Switch>
          <Footer />
        </div>
      </Router>
      <RecaptchaContainer />
    </GoogleReCaptchaProvider>

  );
}

export default App;