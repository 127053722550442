import React from 'react'
import SetDetails from './SetDetails'
import '../style/Specifications.css'
import luxsoft from '../resources/images/luxsoft.png'
import fittedSheet from '../resources/images/fitted-sheet.png'
import prints from '../resources/images/prints.png'

function Specifications() {
    return (
        <div className='specifications'>
            <div className='specifications__panel'>
                <div className='specifications__block'>
                    <p>Set Content</p>
                    <SetDetails />
                </div>
                <div className='specifications__block'>
                    <p>Speciality</p>
                    <div className='featuresgrid'>
                        <div className='image1'>
                            <img
                                src={luxsoft}
                                alt=''
                            />
                        </div>
                        <div className='image2'>
                            <img
                                src={fittedSheet}
                                alt=''
                            />
                        </div>
                        <div className='image3'>
                            <img
                                src={prints}
                                alt=''
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Specifications
