import React from 'react';
import '../style/Story.css'
import Colors from './Colors';
import colorSplash from '../resources/images/paintbrush.jpg';
import Header from './Header';
import Footer from './Footer';
function Story() {
    return (
        <div className='story__section'>
            <Header
                linksEnabled={true}
            />
            {/* <img
                src={colorSplash}
                alt=''
            /> */}
            <div className='story__panel'>
                <div className='story__headline'>
                    <p>OUR STORY</p>
                </div>
                <div className='story__text'>
                    <p>Ataraxia was born out of the realization that just by changing the bedsheets in our rooms,
                        we can change the world around us. Ataraxia is our collective work stemmed from our personal
                        experience of working-from-home during the COVID-19 pandemic.
                    </p>
                    <p>
                        Much like everyone else, we were
                        spending hours on end in our bedrooms.
                        The confines of 4 walls became demotivating, sinking our mood and spirits. Colors were the remedy
                        to all our issues. You dont need to remodel your bedroom to make it more bright and lively simple
                        change of sheets can get you the same effect.
                    </p>
                    <p>
                        Ataraxia is dedicated to creating premium bedsheets that combine bold and floral patterns with soothing
                        colors, creating a balance of alertness and calm. Ataraxia bedsheets, with a burst of colors,
                        can change the atmosphere of your bedroom into one that brings you joy, uplift your life force,
                        and make you feel refreshed.
                    </p>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default Story
